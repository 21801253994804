/**
 * A new and improved language switcher has been developed to match current design specs using Iris components:
 * app/frontend/components/Footer/components/LanguageSwitcher/LanguageSwitcher.tsx
 *
 * The new version should be used in favor of this one for new react development.
 * However, this one is tied to the standalone language-switcher-react-app. So this one should not be removed until that app is removed.
 */
import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, IntlProvider } from 'react-intl';
import styled from 'styled-components';

import devices from 'js/styled/deviceSizes';
import { DEFAULT_LOCALE } from '~/constants';
import {
  getLanguageDisplayName,
  getLocale,
  getMessagesForLocale,
  setLocale,
} from '~/helpers/intl';

const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  label {
    color: #bebebe;
  }
  @media ${devices.mobile} {
    display: block;

    label {
      margin-top: 1rem;
    }
  }
`;

const StyledSelect = styled.select`
  margin-left: 8px;
  border-radius: 3px;
  height: 30px;
  width: 100px;
  padding: 0 5px;
`;

const LanguageSwitcher = (props) => {
  const locale = getLocale();

  return (
    <IntlProvider
      locale={locale}
      messages={getMessagesForLocale(locale)}
      textComponent='span'
    >
      <StyledDiv data-testid='language-switcher'>
        <FormattedMessage
          id='site.language'
          defaultMessage='Language'
          children={(formattedMessage) => {
            return (
              <label htmlFor='language-switcher'>{formattedMessage}&#58;</label>
            );
          }}
        />
        <StyledSelect
          id='language-switcher'
          defaultValue={locale.toLowerCase().slice(0, 2)}
          onChange={handleChange}
        >
          {languageOptions(props)}
        </StyledSelect>
      </StyledDiv>
    </IntlProvider>
  );
};

const languageOptions = (props) => {
  const localesToRender = props.supportedLocales;

  if (localesToRender.indexOf(DEFAULT_LOCALE) === -1) {
    localesToRender.push(DEFAULT_LOCALE);
  }

  return localesToRender.sort().map((locale) => {
    return (
      <option key={locale} value={locale}>
        {optionText(locale)}
      </option>
    );
  });
};

const optionText = (locale) => {
  let lang = getLanguageDisplayName(locale);

  return lang.split('')[0].toUpperCase() + lang.slice(1, lang.length);
};

const handleChange = (event) => {
  const locale = event.target.value;

  setLocale(locale);
  window.location.pathname =
    window.location.pathname; /* eslint-disable-line no-self-assign */
};

LanguageSwitcher.propTypes = {
  supportedLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
};

LanguageSwitcher.defaultProps = {
  supportedLocales: [],
};

export default LanguageSwitcher;
